var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "CSidebar",
    {
      attrs: {
        aside: "",
        show: _vm.$store.state.courierInvoiceAdjustmentsAsideShow,
        colorScheme: "light",
        overlaid: "",
        size: "lg",
      },
      on: {
        "update:show": (val) =>
          _vm.$store.commit("set", ["courierInvoiceAdjustmentsAsideShow", val]),
      },
    },
    [
      _c("CSidebarClose", {
        nativeOn: {
          click: function ($event) {
            return _vm.$store.commit(
              "toggle",
              "courierInvoiceAdjustmentsAsideShow"
            )
          },
        },
      }),
      _c(
        "CListGroup",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showErrorAlert,
              expression: "showErrorAlert",
            },
          ],
          staticClass: "list-group-accent mt-5",
        },
        [
          _c(
            "CListGroupItem",
            {
              staticClass:
                "list-group-item-accent-secondary bg-light text-danger text-center font-weight-bold text-uppercase",
            },
            [_vm._v(" Error ")]
          ),
          _c(
            "CListGroupItem",
            {
              staticClass:
                "list-group-item-accent-danger list-group-item-divider",
              attrs: { href: "#" },
            },
            [
              _c(
                "div",
                { staticClass: "c-avatar float-left mr-2" },
                [
                  _c("CIcon", {
                    staticClass: "c-avatar-img text-danger",
                    attrs: { name: "cil-bug", size: "xl" },
                  }),
                ],
                1
              ),
              _c("div", [_vm._v(_vm._s(_vm.errorAlertMessage))]),
            ]
          ),
        ],
        1
      ),
      !_vm.showErrorAlert && _vm.$store.state.courierInvoiceAdjustmentsAsideShow
        ? _c(
            "CListGroup",
            { staticClass: "scrollable list-group-accent mt-5" },
            [
              [
                _c(
                  "CListGroupItem",
                  {
                    staticClass:
                      "mb-0 list-group-item-accent-secondary bg-light text-center font-weight-bold text-uppercase",
                  },
                  [_vm._v(" ADD ADJUSTMENT ")]
                ),
                _vm.editable
                  ? _c(
                      "CListGroupItem",
                      { staticClass: "list-group-item-accent-success" },
                      [
                        _c("CInput", {
                          staticClass: "mb-1",
                          attrs: {
                            type: "number",
                            label: "Amount:",
                            placeholder: "Adjustment amount",
                            step: "0.01",
                            pattern: "^\\d+(?:\\.\\d{1,2})?$",
                            onblur:
                              "(function(el){el.value=parseFloat(el.value).toFixed(2);})(this)",
                            value: _vm.form.amount,
                          },
                          on: {
                            "update:value": function ($event) {
                              return _vm.$set(_vm.form, "amount", $event)
                            },
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "prepend-content",
                                fn: function () {
                                  return [
                                    _c("CIcon", {
                                      attrs: { name: "cil-euro" },
                                    }),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            false,
                            586003441
                          ),
                        }),
                        _c("CTextarea", {
                          staticClass: "mb-1",
                          attrs: {
                            rows: "2",
                            placeholder: "Note",
                            value: _vm.form.description,
                          },
                          on: {
                            "update:value": function ($event) {
                              return _vm.$set(_vm.form, "description", $event)
                            },
                          },
                        }),
                        _c(
                          "CButton",
                          {
                            attrs: {
                              type: "submit",
                              color: "success",
                              size: "sm",
                              shape: "pill",
                            },
                            on: { click: _vm.addAdjustment },
                          },
                          [
                            _c("CIcon", { attrs: { name: "cil-plus" } }),
                            _vm._v(" Add "),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _c(
                      "CListGroupItem",
                      {
                        staticClass:
                          "list-group-item-accent-danger list-group-item-divider",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "vertical-center flex-gap-2 text-danger",
                          },
                          [
                            _c("CIcon", {
                              attrs: { name: "cil-warning", size: "lg" },
                            }),
                            _vm._v(" This invoice is not editable. "),
                          ],
                          1
                        ),
                        _c("ul", { staticClass: "mt-2 pl-4" }, [
                          _vm.invoice.is_paid
                            ? _c("li", [_vm._v("The invoice has been paid.")])
                            : _c("li", [
                                _vm._v("The invoice has not been paid yet."),
                              ]),
                          _vm.invoice.transfered
                            ? _c("li", [
                                _vm._v("The invoice has been transferred."),
                              ])
                            : _c("li", [
                                _vm._v(
                                  "The invoice has not been transferred yet."
                                ),
                              ]),
                        ]),
                      ]
                    ),
              ],
              _c(
                "CListGroupItem",
                {
                  staticClass:
                    "list-group-item-accent-secondary bg-light text-center font-weight-bold text-uppercase",
                },
                [_vm._v(" Invoice Adjustments ")]
              ),
              _vm._l(_vm.invoice?.item_details?.adjustments, function (adj) {
                return [
                  _c(
                    "CListGroupItem",
                    {
                      staticClass:
                        "list-group-item-divider list-group-item-accent-dark",
                    },
                    [
                      _c("div", { staticClass: "d-flex" }, [
                        _vm._v(
                          " " + _vm._s(_vm._f("toCurrency")(adj.amount)) + " "
                        ),
                        _c("div", { staticClass: "ml-auto small text-muted" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.moment(adj.date).format("DD/MM/YYYY")
                              ) +
                              " "
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "small text-muted" }, [
                        _vm._v(" " + _vm._s(adj.description) + " "),
                      ]),
                    ]
                  ),
                ]
              }),
            ],
            2
          )
        : _vm._e(),
      _c("CElementCover", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.loading,
            expression: "loading",
          },
        ],
        attrs: { opacity: 0.4 },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }